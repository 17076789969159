import React, { useEffect, useState } from "react";
import DataEntry from "./DataEntry";
import ProtectedRoute from "./utils/ProtectedRoute";

import PublicRoute from "./utils/PublicRoute";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Login from "./Login";
import Blogs from "./Blogs";
function App() {

return(


     <BrowserRouter>
    
     <Switch>
      <ProtectedRoute exact path="/data" component={DataEntry} />
      <ProtectedRoute exact path="/blogs" component={Blogs} />
      <PublicRoute exact path="/" component={Login} />
      
      
      
     </Switch>
     </BrowserRouter>
   
)
}
export default App;
