import React, { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { constant } from "./confiq/Confiq";
const Login = () => {
  const history = useHistory();
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const handlechange = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };
 

  const login = async (e) => {
    e.preventDefault();
    try {
      const loginApi = await axios.post(`${constant.apiUrl}/login`, state);
      console.log(loginApi.data);
      localStorage.setItem("auth", "true");
    
      history.push("/data");
      setState({
        state,
      });
    } catch (error) {
      console.log("Not responed", error);
    }
  };

  return (
    <div
      className="container"
      style={{ paddingTop: "100px", margin: "auto", width: "50%" }}
    >
      <div
        className="mb-5"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <img width={200} src="/images/qb-log-black.png" alt="" />
      </div>
      <div>
        <h4 style={{ color: "black" }}>Email</h4>
        <input
          className="inputlogin"
          type="text"
          placeholder="Enter your Email"
          name="email"
          onChange={handlechange}
          value={state.email}
        />
        <br />
        <br />
        <h4 style={{ color: "black" }}>Password</h4>
        <input
          className="inputlogin"
          type="text"
          placeholder="Enter your Email"
          name="password"
          onChange={handlechange}
          value={state.password}
        />
        <br />
        <br />
        <div className="text-center">
          <button style={{ color: "white" }} className="p-btn" onClick={login}>
            Log In
          </button>
        
        </div>
      </div>
    </div>
  );
};

export default Login;
