import "./App.css";
import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { constant } from "./confiq/Confiq";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
function DataEntry() {
  const notify = () => toast("Data Added");
  const history = useHistory();
  const initialFormData = {
    heading: "",
    description: "",
    images: [],
    subHeading: "",
    background: "",
    challange: "",
    solution: "",
    result: "",
    portfolioImages: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formData1, setFormData1] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataAdded, setIsDataAdded] = useState(false);
  const handleRegularInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handlePortfolioImageChange = (e) => {
    setFormData1({ ...formData1, portfolioImages: e.target.files[0] });
  };
  const handleImageChange = (e) => {
    setFormData({ ...formData, images: e.target.files });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      heading,
      description,
      images,
      subHeading,
      background,
      challange,
      solution,
      result,
      portfolioImages,
    } = formData;

    if (
      !heading ||
      heading.trim() === "" ||
      !description ||
      description.trim() === "" ||
      !images ||
      images.length === 0 ||
      !subHeading ||
      subHeading.trim() === "" ||
      !background ||
      background.trim() === "" ||
      !challange ||
      challange.trim() === "" ||
      !solution ||
      solution.trim() === "" ||
      !result ||
      result.trim() === "" ||
      !portfolioImages === ""
    ) {
      setError("Please Enter The Remaning Fields Must");
      return;
    }
    setError("");

    const data = new FormData();
    const data1 = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "images") {
        for (let i = 0; i < formData.images.length; i++) {
          data.append("images", formData.images[i]);
        }
      } else {
        data.append(key, formData[key]);
      }
    });

    // if (formData.portfolioImages) {
    data1.append("portfolioImages", formData1.portfolioImages);
    // }
    try {
      const response = await axios.post(`${constant.apiUrl}/product`, data);
      data1.append("id", response.data._id);
      await axios.post(`${constant.apiUrl}/upload-portfolio-image`, data1);

      console.log("response", response.data);

      // Clear form after successful submission
      setFormData({
        initialFormData,
      });
      setTimeout(() => {
        setIsDataAdded(true); // Indicate that data is added
        setIsLoading(false);
        notify();
      },500);
    } catch (error) {
      console.log("error", error);
    }
  };
  const logout = () => {
    localStorage.removeItem("auth");
    history.push("/");
  };
  return (
    <div>
      <div
        className="breadcrum-area container "
        style={{ paddingTop: "100px" }}
      >
        <h1
          className="text-center pb-5"
          style={{ color: "black", fontWeight: "700" }}
        >
          Quantumbases Form
        </h1>

        <div className="row">
          <div className="col-lg-6">
            <h5>Main Heading : </h5>
            <textarea
              type="text"
              className="inputstyle"
              placeholder=""
              onChange={handleRegularInput}
              name="heading"
              value={formData.heading}
            />{" "}
            <br />
            <br />
            <h5>Introduction</h5>
            <textarea
              type="text"
              onChange={handleRegularInput}
              className="inputstyle"
              name="description"
              value={formData.description}
            />{" "}
            <br />
            <br />
            <h5>Sub Heading</h5>
            <textarea
              type="text"
              onChange={handleRegularInput}
              className="inputstyle"
              name="subHeading"
              value={formData.subHeading}
            />
            <br />
            <br />
            <h5>Background</h5>
            <textarea
              type="text"
              onChange={handleRegularInput}
              className="inputstyle"
              name="background"
              value={formData.background}
            />
            <br />
            <br />
            <h5>Result</h5>
            <textarea
              type="text"
              onChange={handleRegularInput}
              className="inputstyle"
              name="result"
              value={formData.result}
            />
          </div>
          <div className="col-lg-6">
            <h5>Images Carsoules</h5>
            <input
              type="file"
              multiple
              className="inputstyle p-3"
              onChange={handleImageChange}
              name="images"
            />
            <br />
            <br />
            <h5>Images Portfolio Card</h5>
            <input
              type="file"

              multiple
              className="inputstyle p-3"
              onChange={handlePortfolioImageChange}
              name="portfolioImages"
            />
            <br />
            <br />
            <h5>Challenge</h5>
            <textarea
              type="text"
              onChange={handleRegularInput}
              className="inputstyle"
              name="challange"
              value={formData.challange}
            />
            <br />
            <br />
            <h5>Solution</h5>
            <textarea
              type="text"
              onChange={handleRegularInput}
              className="inputstyle"
              name="solution"
              value={formData.solution}
            />
            <Link  to="/blogs">
            <button
              style={{ color: "white" }}
              className="p-btn"
             
              >
             Go to Add Blogs
            </button>
              </Link>
          </div>
        </div>
        {error && (
          <div
            style={{
              color: "white",
              padding: "15px",
              background: "#fd6767",
              borderRadius: "10px",
              marginBottom: "25px",
              marginTop: "20px",
              width: "fit-content",
            }}
          >
            <p>{error}</p>
          </div>
        )}
        <button
          className="p-btn"
          style={{ color: "white" }}
          onClick={handleSubmit}
        >
          {isLoading ? (
            <i className="fa fa-spinner fa-spin"></i>
          ) : isDataAdded ? (
            "Added"
          ) : (
            "Add Data"
          )}
        </button>
        <button
          style={{ color: "white", marginLeft: "10px" }}
          className="p-btn"
          onClick={logout}
        >
          Log out
        </button>

        <ToastContainer />
      </div>
    </div>
  );
}

export default DataEntry;
