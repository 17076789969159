import React, { useState } from "react";
import axios from "axios";
import { constant } from "./confiq/Confiq";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function Blogs() {
  const notify = () => toast("Data Added");
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    metaTitle: "",
    metaDescription: "",
    metaTags: "",
    background: null,
    display: null,
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDataAdded, setIsDataAdded] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.title.trim() ||
      !formData.description.trim() ||
      !formData.background ||
      !formData.display ||
      !formData.metaTitle.trim() ||
      !formData.metaDescription.trim() ||
      !formData.metaTags.trim()
    ) {
      setError("Please enter all required fields.");
      return;
    }

    setError("");
    setIsLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("background", formData.background);
    formDataToSend.append("display", formData.display);
    formDataToSend.append("metaTitle", formData.metaTitle);
    formDataToSend.append("metaTags", formData.metaTags);
    formDataToSend.append("metaDescription", formData.metaDescription);

    try {
      await axios.post(`${constant.apiUrl}/blogslist`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setIsDataAdded(true);
      setIsLoading(false);
      notify();
    } catch (error) {
      console.error("Error uploading files:", error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div
        className="breadcrum-area container "
        style={{ paddingTop: "100px" }}
      >
        <Link to="/data">
          <div style={{ color: "black" }}>{"<"} Back to Case Studies </div>
        </Link>
        <h1
          className="text-center pb-5"
          style={{ color: "black", fontWeight: "700" }}
        >
          Quantumbases Form
        </h1>

        <form onSubmit={handleSubmit}>
          <h5>Title:</h5>
          <input
            className="inputstyle  p-3"
            type="text"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            required
          />
          <br />
          <br />
          <h5>Description:</h5>
          <textarea
            className="inputstyle p-3"
            type="text"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            required
          />
          <br />
          <br />
          <h5>Meta Title:</h5>
          <textarea
            className="inputstyle p-3"
            type="text"
            name="metaTitle"
            value={formData.metaTitle}
            onChange={handleInputChange}
            required
          />

          <br />
          <br />
          <h5>Meta Description:</h5>
          <textarea
            className="inputstyle p-3"
            type="text"
            name="metaDescription"
            value={formData.metaDescription}
            onChange={handleInputChange}
            required
          />
          <br />
          <br />
          <h5>Meta Tags:</h5>
          <textarea
            className="inputstyle p-3"
            type="text"
            name="metaTags"
            value={formData.metaTags}
            onChange={handleInputChange}
            required
          />
          <br />
          <br />
          <h5>Background Image:</h5>
          <input
            className="inputstyle p-3"
            type="file"
            name="background"
            onChange={handleInputChange}
            accept="image/*"
            required
          />
          <br />
          <br />
          <h5>Display Image:</h5>
          <input
            className="inputstyle p-3"
            type="file"
            name="display"
            onChange={handleInputChange}
            accept="image/*"
            required
          />
          <br />
          <br />
          <button className="p-btn" style={{ color: "white" }} type="submit">
            {isLoading ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : isDataAdded ? (
              "Uploaded"
            ) : (
              "Upload"
            )}
          </button>

          {error && (
            <div
              style={{
                color: "white",
                padding: "15px",
                background: "#fd6767",
                borderRadius: "10px",
                marginBottom: "25px",
                marginTop: "20px",
                width: "fit-content",
              }}
            >
              <p>{error}</p>
            </div>
          )}

          <ToastContainer />
        </form>
      </div>
    </div>
  );
}

export default Blogs;
