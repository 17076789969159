// ProtectedRoute.js
import React from "react";
import { Route, Redirect } from "react-router-dom";

const PublicRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem("auth"); // Adjust the key based on your implementation
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <div> <Redirect to="/data" /><Redirect to="/blogs"/></div> : <Component {...props} />
      }
    />
  );
};

export default PublicRoute;
